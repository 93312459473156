<template>
  <div class="notice">
    <top-bar :title="'办事指南'" :left="true"></top-bar>
    <form action="/">
      <van-search v-model="searchValue" show-action placeholder="请输入搜索关键词" @search="onSearch" @cancel="onCancel" />
    </form>
    <div class="cont">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
        v-if="dataList.length > 0" offset="10">
        <van-cell v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
          <div class="item">
            <p class="itemTitle">{{ item.title }}</p>
            <p class="itemOrg"><span>{{ item.orgName }}</span></p>
            <span>时间: {{ item.createTime }}</span>
          </div>
        </van-cell>
      </van-list>
      <van-empty description="没有数据哦" v-else />
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import { Toast } from 'vant'

import { mapMutations } from 'vuex'
export default {
  components: {
    topBar
  },
  data() {
    return {
      searchValue: '',
      loading: false,
      finished: false,
      limit: 10,
      page: 0,
      dataList: []
    };
  },
  methods: {
    ...mapMutations,
    getInfo(id) {
      this.$store.commit('setGuideId', id)
      this.$router.push('/guide-info')
    },
    getDataList() {
      this.page++
      this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true, });
      this.$http({
        url: this.$http.adornUrl('/wxapp/guide/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          title: this.searchValue,  //搜索条件
          appOrgId: this.$orgId,
          orgId: this.$orgId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
      })
      // 数据全部加载完成
    },
    onSearch(val) {
      this.page = 0
      this.dataList = []
      this.finished = false
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.finished = false
      this.getDataList()
    },
  },
  created() {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDataList()
  }
}
</script>

<style lang="scss" scoped>
.notice {
  padding-top: 100px;

  .cont {
    padding: 0 20px 20px;
    background-color: #f5f5f5;

    .item {
      padding: 20px;
      overflow: hidden;

      .itemTitle {
        display: inline-block;
        width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .itemOrg {
        float: right;
      }

      p {
        margin: 0;
        font-size: 30px;

        span {
          color: #00a2d3;
          font-size: 30px;
        }
      }

      span {
        display: block;
        font-size: 22px;
      }
    }
  }
}
</style>
